import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/8.jpg)`,
                        }}
                    >
                        <h1>PURIFINES - Lavadora de finos PET</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El sistema de limpieza y lavado de los finos es un proceso desarrollado para limpiar y
                                reciclar botellas de PET de mayor y mediano tamaño, así como también al procesamiento de
                                reciclaje centralizado de polvo y escamas de botellas de PET. Es un equipo de tecnología
                                avanzada y amigable con el medio ambiente, para el procesamiento de desechos mezclados
                                de PET. El sistema consta de una lavadora de finos y su conjunto de equipos envuelto del
                                proceso de reciclaje, lavado y trituración de botellas de PET, el fino de PET producido
                                en el proceso de deshidratación centrífuga y los materiales del piso en el proceso de
                                lavado. Después de la separación, lavado, secado y otras tecnologías de proceso del
                                sistema, el índice de finos de PET terminado puede alcanzar el estándar de hilado de
                                fibra cortada y puede aplicarse directamente en la máquina. El propósito de este sistema
                                es aumentar la tasa de reciclaje de los productos terminados en las fábricas de PET
                                reciclado y aumentar el valor agregado de los productos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
